<template>
  <overlay
    :show="loading"
  >
    <div class="content-wrapper">
      <page-header
        screen-name="Cadastrar adesão nacional"
        :link-items="linkItems"
      />

      <main class="card">
        <div class="mx-2 m-2">
          <FormGrupoCampanhaOperador
            :botao-avancar="true"
            @setLoading="setLoading"
          />
        </div>
      </main>
    </div>
  </overlay>
</template>

<script>
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import FormGrupoCampanhaOperador from './Forms/FormGrupoCampanhaOperador.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'

export default {
  title: 'Cadastrar adesão nacional',

  components: {
    Overlay,
    PageHeader,
    FormGrupoCampanhaOperador,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Adesão Nacional',
          active: true,
          routeName: 'listar-adesao-nacional',
        },
        {
          name: 'Aderir Campanha',
          active: true,
          routeName: 'choose-campaign-nacional',
        },
      ],

      loading: true,
    }
  },

  methods: {
    setLoading(loading) {
      this.loading = loading
    },
  },
}
</script>
